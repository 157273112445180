.app-shell {
  width: 98rem;
  max-width: 90%;
  padding: 4.5rem 0;
  margin: 0 auto;
}

@media (--viewportMobileTablet) {
  .app-shell {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
}

/* stylelint-disable-next-line selector-max-universal */
*,
*::before,
*::after {
  box-sizing: inherit;
}

:global(.html) {
  box-sizing: border-box;
  font-size: 62.5%;
}

:global(.body) {
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  font: normal var(--fontSizeRegular) Roboto, sans-serif;
  color: var(--greyDark);
}

:global(.html),
:global(.body),
:global(.root) {
  height: 100%;
}
